import Vue from 'vue'
import VueRouter from 'vue-router'

//修复当前页面刷新后，重新点击当前路由控制台会报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'layout',
  redirect: '/home',
  component: () => import('@/views/layout/index.vue'),
  children: [
    // 登录
    {
      path: 'signin',
      name: 'Signin',
      component: () => import('@/views/sign/signin.vue')
    },
    {
      path: 'signup',
      name: 'signup',
      component: () => import('@/views/sign/signup.vue')
    },
    {
      path: 'notice',
      name: 'notice',
      component: () => import('@/views/notice/index.vue')
    },
    {
      path: 'redirect',
      name: 'redirect',
      component: () => import('@/views/redirect.vue')
    },
    {
      path: 'personal-center',
      name: 'personalCenter',
      component: () => import('@/views/personal/')
    },
    {
      path: 'forgot',
      name: 'modifyPass',
      component: () => import('@/views/forgot/')
    },
    // 首页
    {
      path: 'home',
      name: 'home',
      redirect: '/home/basic-information',
      component: () => import('@/views/home/index.vue'),
      children: [{
        path: 'common-info',
        name: 'common-info',
        component: () => import('@/views/common-info/index.vue')
      }, {
        path: 'upload-responsibility',
        name: 'uploadResponsibility',
        component: () => import('@/views/home/upload-responsibility/index.vue')
      }, {
        path: 'basic-information',
        name: 'basicInformation',
        component: () => import('@/views/home/basic-information/index.vue')
      }, {
        path: 'item-information',
        name: 'itemInformation',
        component: () => import('@/views/home/item-information/index.vue')
      }, {
        path: 'item-summary',
        name: 'itemSummary',
        component: () => import('@/views/home/item-summary/index.vue')
      }, {
        path: 'upload-prove',
        name: 'uploadProve',
        component: () => import('@/views/home/upload-prove/index.vue')
      }, {
        path: 'submit-review',
        name: 'submitReview',
        component: () => import('@/views/home/submit-review/index.vue')
      }]
    }
    // 修改
    // {
    //   path: 'update',
    //   name: 'update',
    //   redirect: '/update/common-info',
    //   component: () => import('@/views/update/index.vue'),
    //   children: [{
    //     path: 'common-info',
    //     name: 'common-info',
    //     component: () => import('@/views/common-info/index.vue')
    //   }, {
    //     path: 'upload-responsibility',
    //     name: 'uploadResponsibility',
    //     component: () => import('@/views/home/upload-responsibility/index.vue')
    //   }, {
    //     path: 'basic-information',
    //     name: 'basicInformation',
    //     component: () => import('@/views/home/basic-information/index.vue')
    //   }, {
    //     path: 'item-information',
    //     name: 'itemInformation',
    //     component: () => import('@/views/home/item-information/index.vue')
    //   }, {
    //     path: 'item-summary',
    //     name: 'itemSummary',
    //     component: () => import('@/views/home/item-summary/index.vue')
    //   }, {
    //     path: 'upload-prove',
    //     name: 'uploadProve',
    //     component: () => import('@/views/home/upload-prove/index.vue')
    //   }]
    // }
    // 常用运动员
    // {
    //   path: 'commonInfo',
    //   name: 'commonInfo',
    //   component: () => import('@/views/common-info/index.vue')
    // }
  ]
},
{
  path: '/admin',
  name: 'admin',
  redirect: '/admin/index',
  component: () => import('@/views/layout-admin/index.vue'),
  children: [{
    path: 'index',
    name: 'adminIndex',
    component: () => import('@/views/admin/index.vue')
  },
  {
    path: 'review',
    name: 'adminReview',
    component: () => import('@/views/admin/review.vue')
  },
  {
    path: 'setting',
    name: 'settingReview',
    component: () => import('@/views/admin/setting.vue')
  }
  ]
}
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
