/*
 * @Author: Saving
 * @Date: 2024-07-02 23:00:13
 * @LastEditors: Saving
 * @LastEditTime: 2024-08-07 22:54:57
 * @Description: 请输入
 */
export function nameFormat(contestants, showGroupName = true) {
  if (contestants && contestants.name && contestants.name.length > 0) {
    return `${contestants.name}(${contestants.cardId.substr(-6, 6)})${
      showGroupName ? contestants.group : ""
    }`;
  } else {
    return "";
  }
}
