/*
 * @Author: ChileeHong
 * @Date: 2021-03-01 17:10:55
 * @LastEditors: aardpro
 * @LastEditTime: 2023-06-20 14:34:23
 * @Description: 安全的route.push方法
 */
import router from "../router/index";

/**
 * @description: 安全的路由跳转
 * @param {string|object} location 符合路由规则的对象，字符串
 * @param {Function|undefined} onComplete
 * @param {Function|undefined} onAbort
 * @param {"push"|"replace"} action
 * @return {void}
 */
function savePush(location, onComplete, onAbort, action = "push") {
  const current = router.currentRoute;
  const { resolved } = router.resolve(location);

  // 判断当前路由跟目标路由是否一致，如是则取消，避免重复路由
  if (
    current.path === resolved.path &&
    JSON.stringify(current.query) === JSON.stringify(resolved.query) &&
    JSON.stringify(current.params) === JSON.stringify(resolved.params)
  ) {
    onComplete && onComplete();
    return;
  }

  router[action](
    location,
    (success) => {
      onComplete && onComplete(success);
    },
    (err) => {
      onAbort && onAbort(err);
    }
  );
}

export default savePush;
