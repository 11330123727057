/*
 * @Desc: 描述
 * @Date: 2020-04-19 15:45:06
 * @LastEditTime: 2024-04-29 23:30:40
 */
import { BASE_URL } from "@/const";
import request from "@/utils/request";

/**
 * 登录 api
 */
export function signin(data) {
  return request({
    url: `${BASE_URL}/api/sign/signin`,
    method: "post",
    data
  });
}

/**
 * 注册 api
 */
export function signup(data) {
  return request({
    url: `${BASE_URL}/api/sign/signup`,
    method: "post",
    data
  });
}
/**
 * 退出登录 api
 */
export function signout(data) {
  return request({
    url: `${BASE_URL}/api/sign/signout`,
    method: "post",
    data
  });
}

// 获取常用运动员列表
export function getMembers(data) {
  return request({
    url: `${BASE_URL}/api/member/list`,
    method: "get",
    data
  });
}

// 常用运动员-添加
export function addMember(data) {
  return request({
    url: `${BASE_URL}/api/member`,
    method: "post",
    data
  });
}
// 常用运动员-获取运动员信息
export function getMember(id) {
  return request({
    url: `${BASE_URL}/api/member/${id}`,
    method: "get"
  });
}
// 常用运动员-删除
export function delMember(id) {
  return request({
    url: `${BASE_URL}/api/member/${id}`,
    method: "delete"
  });
}

// 常用运动员-修改
export function putMember(id, data) {
  return request({
    url: `${BASE_URL}/api/member/${id}`,
    method: "put",
    data
  });
}

// 保存团队基本信息
export function saveTeam(data) {
  return request({
    url: `${BASE_URL}/api/team`,
    method: "post",
    data
  });
}
// 获取团队基本信息
export function getTeam() {
  return request({
    url: `${BASE_URL}/api/team`,
    method: "get"
  });
}
// 获取所有项目报项信息
export function getAll() {
  return request({
    url: `${BASE_URL}/api/all`,
    method: "get"
  });
}

// 报项-个人-获取列表
export function getPersons(itemType) {
  return request({
    url: `${BASE_URL}/api/persons`,
    method: "get",
    params: {
      itemType
    }
  });
}

// 报项-个人-添加
export function addPerson(data) {
  return request({
    url: `${BASE_URL}/api/person`,
    method: "post",
    data
  });
}
// 报项-个人-修改
export function putPerson(id, data) {
  return request({
    url: `${BASE_URL}/api/person/${id}`,
    method: "put",
    data
  });
}
// 报项-个人-删除
export function delPerson(id) {
  return request({
    url: `${BASE_URL}/api/person/${id}`,
    method: "delete"
  });
}

// 报项-对练-获取列表
export function getDuels() {
  return request({
    url: `${BASE_URL}/api/duels`,
    method: "get"
  });
}
// 报项-对练-添加
export function addDuel(data) {
  return request({
    url: `${BASE_URL}/api/duel`,
    method: "post",
    data
  });
}
// 报项-对练-修改
export function putDuel(id, data) {
  return request({
    url: `${BASE_URL}/api/duel/${id}`,
    method: "put",
    data
  });
}
// 报项-对练-删除
export function delDuel(id) {
  return request({
    url: `${BASE_URL}/api/duel/${id}`,
    method: "delete"
  });
}

// 报项-集体-获取列表
export function getCollectives() {
  return request({
    url: `${BASE_URL}/api/collectives`,
    method: "get"
  });
}
// 报项-集体-添加
export function addCollective(data) {
  return request({
    url: `${BASE_URL}/api/collective`,
    method: "post",
    data
  });
}
// 报项-对练-修改
export function putCollective(id, data) {
  return request({
    url: `${BASE_URL}/api/collective/${id}`,
    method: "put",
    data
  });
}
// 报项-集体-删除
export function delCollective(id) {
  return request({
    url: `${BASE_URL}/api/collective/${id}`,
    method: "delete"
  });
}

// 获取责任书列表
export function getResponsibility() {
  return request({
    url: `${BASE_URL}/api/upload/responsibilitys`,
    method: "get"
  });
}
// 删除责任书列表
export function delResponsibility() {
  return request({
    url: `${BASE_URL}/api/upload/responsibility`,
    method: "delete"
  });
}
// 获取汇款证明
export function getProve() {
  return request({
    url: `${BASE_URL}/api/upload/proves`,
    method: "get"
  });
}
// 删除汇款证明
export function delProve() {
  return request({
    url: `${BASE_URL}/api/upload/prove`,
    method: "get"
  });
}
// 提交
export function formSubmit(data) {
  return request({
    url: `${BASE_URL}/api/form/submit`,
    method: "post",
    data
  });
}
// 审核未通过重新提交
export function formReSubmit(data) {
  return request({
    url: `${BASE_URL}/api/form/resubmit`,
    method: "post",
    data
  });
}

// 获取常用运动员列表
export function getMembersAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/member/list`,
    method: "get",
    data
  });
}
// 常用运动员-添加
export function addMemberAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/member`,
    method: "post",
    data
  });
}
// // 常用运动员-获取运动员信息
// export function getMemberAdmin (id) {

//   return request({
//     url: `${BASE_URL}/admin/member/${id}`,
//     method: 'get'
//   })
// }
// 常用运动员-删除
export function delMemberAdmin(id) {
  return request({
    url: `${BASE_URL}/admin/member/${id}`,
    method: "delete"
  });
}
// 常用运动员-修改
export function putMemberAdmin(id, data) {
  return request({
    url: `${BASE_URL}/admin/member/${id}`,
    method: "put",
    data
  });
}

// 保存团队基本信息
export function saveTeamAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/team`,
    method: "post",
    data
  });
}
// 获取团队基本信息
export function getTeamAdmin() {
  return request({
    url: `${BASE_URL}/admin/team`,
    method: "get"
  });
}
// 获取所有项目报项信息
export function getAllAdmin() {
  return request({
    url: `${BASE_URL}/admin/all`,
    method: "get"
  });
}
// 报项-个人-获取列表
export function getPersonsAdmin() {
  return request({
    url: `${BASE_URL}/admin/persons`,
    method: "get"
  });
}
// 报项-个人-添加
export function addPersonAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/person`,
    method: "post",
    data
  });
}
// 报项-个人-修改
export function putPersonAdmin(id, data) {
  return request({
    url: `${BASE_URL}/admin/person/${id}`,
    method: "put",
    data
  });
}
// 报项-个人-删除
export function delPersonAdmin(id) {
  return request({
    url: `${BASE_URL}/admin/person/${id}`,
    method: "delete"
  });
}

// 报项-对练-获取列表
export function getDuelsAdmin() {
  return request({
    url: `${BASE_URL}/admin/duels`,
    method: "get"
  });
}
// 报项-对练-添加
export function addDuelAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/duel`,
    method: "post",
    data
  });
}
// 报项-对练-修改
export function putDuelAdmin(id, data) {
  return request({
    url: `${BASE_URL}/admin/duel/${id}`,
    method: "put",
    data
  });
}
// 报项-对练-删除
export function delDuelAdmin(id) {
  return request({
    url: `${BASE_URL}/admin/duel/${id}`,
    method: "delete"
  });
}

// 报项-集体-获取列表
export function getCollectivesAdmin() {
  return request({
    url: `${BASE_URL}/admin/collectives`,
    method: "get"
  });
}
// 报项-集体-添加
export function addCollectiveAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/collective`,
    method: "post",
    data
  });
}
// 报项-对练-修改
export function putCollectiveAdmin(id, data) {
  return request({
    url: `${BASE_URL}/admin/collective/${id}`,
    method: "put",
    data
  });
}
// 报项-集体-删除
export function delCollectiveAdmin(id) {
  return request({
    url: `${BASE_URL}/admin/collective/${id}`,
    method: "delete"
  });
}

// 获取责任书列表
export function getResponsibilityAdmin() {
  return request({
    url: `${BASE_URL}/admin/upload/responsibilitys`,
    method: "get"
  });
}
// 删除责任书列表
export function delResponsibilityAdmin() {
  return request({
    url: `${BASE_URL}/admin/upload/responsibility`,
    method: "delete"
  });
}
// 获取汇款证明
export function getProveAdmin() {
  return request({
    url: `${BASE_URL}/admin/upload/proves`,
    method: "get"
  });
}
// 删除汇款证明
export function delProveAdmin() {
  return request({
    url: `${BASE_URL}/admin/upload/prove`,
    method: "get"
  });
}

// 提交
export function formSubmitAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/form/submit`,
    method: "post",
    data
  });
}

/**
 * 后台管理 - 首页
 * 获取所有数据汇总信息
 */
export function getAllCount() {
  return request({
    url: `${BASE_URL}/admin/count`,
    method: "get"
  });
}

export function getAllList(query) {
  return request({
    url: `${BASE_URL}/admin/list`,
    method: "get",
    params: query
  });
}

export function getAdminTeam(query) {
  return request({
    url: `${BASE_URL}/admin/teamInfo`,
    method: "get",
    params: query
  });
}
// 后台管理 - 打回
export function teamReject(data) {
  return request({
    url: `${BASE_URL}/admin/reject`,
    method: "post",
    data
  });
}
// 后台管理 - 通过
export function teamPass(id, data) {
  return request({
    url: `${BASE_URL}/admin/pass/${id}`,
    method: "post",
    data
  });
}

// 后台管理 - 系统设置 20200804 new
// 保存报名时间
export function setMatchTime(data) {
  return request({
    url: `${BASE_URL}/admin/time/set`,
    method: "post",
    data
  });
}

// 获取报名时间
export function getMatchTime() {
  return request({
    url: `${BASE_URL}/admin/time/get`,
    method: "get"
  });
}

// 用户导出报名信息
export function outputInfo(data) {
  return request({
    url: `${BASE_URL}/api/output`,
    method: "post",
    data,
    responseType: "blob"
  });
}

// 管理员导出报名信息
export function outputInfoAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/output`,
    method: "post",
    data,
    responseType: "blob"
  });
}

// 获取手机验证码
export function sendValidateCode(data) {
  return request({
    url: `${BASE_URL}/api/common/sendcode`,
    method: "post",
    data
  });
}

// 修改個人信息
export function modifyPersonalInfo(data) {
  return request({
    url: `${BASE_URL}/api/common/modifyinfo`,
    method: "post",
    data
  });
}

// 通过帐号修改密码
export function modifyPassword(data) {
  return request({
    url: `${BASE_URL}/api/common/modifypass`,
    method: "post",
    data
  });
}

// 管理员导出所有已审核报名信息
export function outputPassInfoAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/all/outputpass`,
    method: "post",
    data,
    responseType: "blob"
  });
}

// 获取所有队伍项目汇总
export function outputProjectSummary(data) {
  return request({
    url: `${BASE_URL}/admin/all/outputProjectSummary`,
    method: "post",
    data,
    responseType: "blob"
  });
}

