/*
 * @Desc: 描述
 * @Date: 2020-01-11 22:02:21
 * @LastEditTime: 2020-07-25 15:55:46
 */
/**
 * Created by 麦锦超 on 2018/6/7.
 */
import { Message } from 'element-ui';
import store from '@/store'
import {
  getToken,
  getCookie
} from '@/utils/auth'

import axios from 'axios'
// axios.defaults.withCredentials = false;
// 对所有 axios 请求做处理,允许携带cookies
axios.defaults.withCredentials = true

const service = axios.create({
  timeout: 5000
})

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers.common['X-Token'] = getToken();
    config.headers.common['X-AccountType'] = getCookie('authority');
  }
  config.headers.common['X-Author'] = store.getters.author === '' ? undefined : store.getters.author;
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})


// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.log(res)
    // 统一请求的异常处理
    // if (res.status !== 200) {
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
    if (res.code === -1) {
      // return Promise.reject(new Error(res.message || 'Error'))
      return Promise.reject(res.message || 'Error')
      // window.href = '/#/login'
    } else {
      return Promise.resolve(response)
    }

  },
  error => {
    Message.error('网络状态不佳')
    // console.log(`err${error}`) // for debug
    return Promise.reject(error)
  }
)
export default service
