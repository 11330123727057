/*
 * @Desc: 路由守卫
 * @Date: 2020-03-10 23:24:43
 * @LastEditTime: 2020-06-21 16:07:40
 */
import store from '@/store'
import Router from '@/router/index'
// 引入默认字段配置文件
// import Default from 'pro/config/default.js'
// const whiteList = ['/signin', '/signup']
Router.beforeEach((to, from, next) => {
  store.commit('SET_AUTHOR', store.state.user.id)
  if (to.path.indexOf('/home/') !== -1) {
    if (store.state.user.authority === 1 && to.query.id) {
      store.commit('SET_AUTHOR', to.query.id)
      next()
    } else if (store.state.user.authority === 1 && !to.query.id) {
      next({
        path: '/redirect'
      })
    } else {
      next()
    }

  } else if (to.path.indexOf('/admin/') !== -1 && store.state.user.authority !== 1) {
    console.log(from.path)
    next('/redirect')

  } else {
    next()
  }
  // next()

})
